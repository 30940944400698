import {useState, useEffect, ChangeEvent} from 'react'
import {DateType} from './SubscriptionSelectionModalForm'

type Props = {
  setDate: (date: DateType) => void
}

type changeEvent = ChangeEvent<HTMLInputElement>

// const numberRegex = /^[0-9][0-9]$/
export default function SubscriptionSelectionDateInput({setDate}: Props) {
  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    setDate({
      m: parseInt(month),
      d: parseInt(day),
      y: parseInt(year),
    })
  }, [day, month, year, setDate])

  const getSafeValue = (unsafeValue: string) => unsafeValue.replace(/\D/g, '')

  function handleMonthChange({target: {value}}: changeEvent) {
    const safeValue = getSafeValue(value)

    if (safeValue.length > 2) {
      // take third number, send it to the month
      setDay(safeValue.substring(2))

      // move focus to next input
      document.getElementById('d')!.focus()
    } else {
      setMonth(safeValue)
    }
  }

  function handleDayChange({target: {value}}: changeEvent) {
    if (value === '') {
      document.getElementById('m')!.focus()
      setDay(value)
    } else {
      const safeValue = getSafeValue(value)

      if (safeValue.length > 2) {
        // take third number, send it to the year
        setYear(safeValue.substring(2))

        // move focus to next input
        document.getElementById('y')!.focus()
      } else {
        setDay(safeValue)
      }
    }
  }

  function handleYearChange({target: {value}}: changeEvent) {
    if (value === '') {
      document.getElementById('d')!.focus()
      setYear(value)
    } else {
      const safeValue = getSafeValue(value)

      if (safeValue.length < 5) {
        setYear(safeValue)
      }
    }
  }

  return (
    <div className="flex justify-center">
      <label className="text-xl pr-4 pt-1 text-semibold">Due Date:</label>
      <div className="flex">
        <input
          name="m"
          id="m"
          type="text"
          style={{width: '35px', height: '30px'}}
          className="focus:outline-none pt-2 pl-1 border-gray-500 border-b"
          value={month}
          onChange={handleMonthChange}
          placeholder="MM"
        />
        <span className="text-lg leading-loose">/</span>
        <input
          name="d"
          id="d"
          type="text"
          style={{width: '30px', height: '30px'}}
          className="focus:outline-none pt-2 pl-1 border-gray-500 border-b"
          value={day}
          onChange={handleDayChange}
          placeholder="DD"
        />
        <span className="text-lg leading-loose">/</span>
        <input
          name="y"
          id="y"
          type="text"
          style={{width: '44px', height: '30px'}}
          className="focus:outline-none pt-2 pl-1 border-gray-500 border-b"
          value={year}
          onChange={handleYearChange}
          placeholder="YYYY"
        />
      </div>
    </div>
  )
}
