import {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'
import * as yup from 'yup'

import {userApi, ResetPasswordBody} from 'api/user'
import routes from 'constants/routes'
import {truncateErrorString} from 'core/error'
import Modal from 'common/Modal'
import Input from 'common/inputs/Input'
import ErrorMessage from 'common/ErrorMessage'
import MainButton from 'common/buttons/MainButton'
import {PASSWORD_REGEX} from 'constants/regularExpressions'
import {useWindowSize} from 'hooks/useWindowSize'

type Form = Omit<ResetPasswordBody, 'confirmation_code'>

export default function ResetPassword() {
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const location = useLocation()
  const history = useHistory()
  const {width} = useWindowSize()

  const formik = useFormik<Form>({
    initialValues: {
      new_password: '',
      confirm_password: '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: hasTriedToSubmit,
    validationSchema: yup.object().shape({
      new_password: yup
        .string()
        .label('New Password')
        .required()
        .matches(
          PASSWORD_REGEX,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
      confirm_password: yup
        .string()
        .label('Password confirmation')
        .required()
        .test('passwords-match', 'Passwords must match', function (value) {
          return this.parent.new_password === value
        }),
    }),
    validate: () => setHasTriedToSubmit(true),
    onSubmit: async values => {
      const re = /\/reset-password\/(.+)/
      const confirmation_token = location.pathname.match(re)
      const res = await userApi.resetPassword({
        ...values,
        confirmation_code: confirmation_token?.[1] ?? '',
      })
      if (res.success) {
        if (width > 760) {
          toast.success('Your password has been changed!')
          setTimeout(() => {
            toast.info('You are now being redirected to the login page')
          }, 2000)

          setTimeout(() => history.push(routes.LOGIN), 7000)
        } else {
          setShowModal(true)
        }
      } else {
        let msg: string

        if (res.data.response?.data) {
          msg = truncateErrorString(res.data.response?.data)
        } else {
          msg = `Something happened while resetting your password`
        }

        toast.error(`There was an error: ${msg}`)
      }
    },
  })

  return (
    <main className="flex justify-center">
      <form
        onSubmit={formik.handleSubmit}
        className="flex justify-center flex-col md:w-5/12 sm:w-2/3 w-11/12 mt-28"
      >
        <h1 className="text-3xl mb-10">Reset Password</h1>
        <ErrorMessage value={formik.errors.new_password} />
        <Input
          className={formik.values.new_password ? 'font-bold' : ''}
          name="new_password"
          value={formik.values.new_password}
          onChange={formik.handleChange}
          placeholder="New Password"
        />
        <ErrorMessage value={formik.errors.confirm_password} />
        <Input
          className={formik.values.confirm_password ? 'font-bold' : ''}
          name="confirm_password"
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          placeholder="Confirm Password"
        />
        <MainButton type="submit" disabled={!formik.isValid} className="mt-20">
          Reset Password
        </MainButton>
        {showModal && (
          <Modal
            handleClose={() => setShowModal(false)}
            title="Your password has been changed!"
          >
            <ResetPasswordModal />
          </Modal>
        )}
      </form>
    </main>
  )
}

function ResetPasswordModal() {
  const history = useHistory()

  return (
    <form>
      <h2 className="text-center mt-10 text-lg">
        Where would you like to be redirected to?
      </h2>
      <div className="flex md:flex-row flex-col-reverse w-full mt-4">
        <MainButton
          type="button"
          onClick={() => (window.location.href = 'wilmafit://')}
          className="md:mr-2"
        >
          Wilma Fit Mobile App
        </MainButton>
        <MainButton
          type="button"
          onClick={() => history.push(routes.LOGIN)}
          className="md:ml-2"
        >
          Website Portal
        </MainButton>
      </div>
    </form>
  )
}
