import {createContext, useContext, useReducer, Dispatch} from 'react'
import {toast} from 'react-toastify'

import {User, UserAction} from 'types'

import {userApi} from 'api/user'
import {PLAN_SELECTION} from 'constants/routes'
import {USER} from 'constants/defaults/user.default'
import LocalStorage from 'core/localStorage'
import {truncateErrorString} from 'core/error'

const UserContext = createContext([
  {} as User,
  {} as Dispatch<UserAction>,
] as const)

/** because useReducer is synchronous, this wrapper allows for asynchronous function to be executed before state is updated  */
// prettier-ignore
function dispatchMiddleware(dispatch: Dispatch<UserAction>): (action: UserAction) => Promise<void> {
  return async (action: UserAction): Promise<void> => {
    switch (action.type) {
      case 'login': {
        const response = await userApi.login(action.data)
        if (response.success) {
          LocalStorage.set('bearer_token', response.data.key)
          action.middleware = await userApi.getUser()
        } else {
          toast.error(response.data.response?.data?.['non_field_errors']?.[0] ?? 'There was a problem logging in')
        }
        
        dispatch(action)
      }
      break
      case 'logout': {
        const response = await userApi.logout()
        if (!response.success) {
          toast.error("There was a problem logging you out...")
        }
        
        dispatch(action)
        localStorage.clear()
        window.location.assign('/')
      }
      break
      default:
        dispatch(action)
    }
  }
}

// synchronous logic goes here
function userReducer(prevState: User, action: UserAction): User {
  switch (action.type) {
    case 'login': {
      if (action.middleware) {
        const response = action.middleware
        if (response.success) {
          if (!response.data.user_profile.is_email_verified) {
            LocalStorage.set('is_email_verified', false)
            toast.error('Your email has not been verified.')
          } else {
            LocalStorage.set('is_email_verified', true)
            const {has_stripe_account} = response.data.user_profile
            if (!has_stripe_account) {
              location.assign(PLAN_SELECTION)
            }
          }
          LocalStorage.set('user', response.data)
          return response.data
        } else {
          const msg = truncateErrorString(
            response.data.response?.data ?? 'Something went wrong...',
          )

          toast.error(msg)
        }
      }
      break
    }
    case 'logout':
      return USER
    case 'set':
      LocalStorage.set('user', action.data)
      return action.data
    default:
      break
  }
  return {} as User
}

type Props = {
  children: React.ReactNode
}

/** JSX wrapper to provide user context */
export function UserContextProvider({children}: Props) {
  const [state, dispatch] = useReducer(userReducer, USER)

  return (
    <UserContext.Provider value={[state, dispatchMiddleware(dispatch)]}>
      {children}
    </UserContext.Provider>
  )
}

/** context hook for the user and its dispatch */
export function useUserContext(): readonly [
  user: User,
  userDispatch: Dispatch<UserAction>,
] {
  const context = useContext(UserContext)
  if (!context)
    throw new Error('Context must used within scope of UserContextProvider')
  return context
}
