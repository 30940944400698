import {useState, useEffect, CSSProperties, MouseEvent} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'

import PregnancyGuideImage from 'assets/images/pregnancy_guide.png'
import RegularGuideImage from 'assets/images/regular_guide.png'

import {GuideSelections} from 'types'

import {internalStripeApi} from 'api/stripe'
import routes from 'constants/routes'
import {truncateErrorString} from 'core/error'
import LocalStorage from 'core/localStorage'
import {useWindowSize} from 'hooks/useWindowSize'
import {useUserContext} from 'context/user.context'
import {USER} from 'constants/defaults'

type Props = {isAuth: boolean}

export default function GuideSelection({isAuth}: Props) {
  const [regularPrice, setRegularPrice] = useState('$9.99')
  const [pregnancyPrice, setPregnancyPrice] = useState('$12.99')

  const history = useHistory()

  const {
    width,
    breakpoints: {sm, xs},
  } = useWindowSize()

  let user = USER

  const [contextUser] = useUserContext()
  const localUser = LocalStorage.get('user')
  if (localUser.id !== USER.id && contextUser.id === USER.id) {
    user = localUser
  } else if (contextUser.id !== USER.id) {
    user = contextUser
  }

  const hasPreviousSub = user.user_profile.subscriptions.length > 0
  const [hasPregnancy] = user.user_profile.subscriptions.map(
    s => s.is_pregnancy,
  )

  if (user.user_profile.subscriptions.length > 1) {
    return <Redirect to={routes.ONBOARDING_COMPLETE} />
  }

  useEffect(() => {
    async function fetchData() {
      const response = await internalStripeApi.getProducts()
      if (response.success) {
        const [newRegularPrice, newPregnancyPrice] = response.data.reduce(
          (prevValue, currValue) => {
            if (currValue.name.startsWith('Regular')) {
              currValue.prices.forEach(({unit_amount, active}) => {
                if (unit_amount < prevValue[0] && active) {
                  prevValue[0] = unit_amount
                }
              })
            } else {
              currValue.prices.forEach(({unit_amount, active}) => {
                if (unit_amount < prevValue[1] && active) {
                  prevValue[1] = unit_amount
                }
              })
            }
            return prevValue
          },
          [9999, 9999],
        )

        const UsdFormatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })

        setRegularPrice(UsdFormatter.format(newRegularPrice / 100))
        setPregnancyPrice(UsdFormatter.format(newPregnancyPrice / 100))
      } else {
        const msg = truncateErrorString(
          response.data.response?.data ??
            'There was an error retrieving products',
        )

        toast.error(msg)
      }
    }

    fetchData()
  }, [])

  // prettier-ignore
  function handleClick(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void {
    const {id} = e.target as HTMLButtonElement
    LocalStorage.set('guideSelection', id as GuideSelections)
    history.push(isAuth ? routes.PLAN_SELECTION : routes.SIGN_UP)
  }

  let sectionWidth = width - 20 + 'px'

  if (width > sm) {
    sectionWidth = '420px'
  } else if (width > xs) {
    sectionWidth = '350px'
  }

  const sectionStyle = (isPregnancy: boolean) =>
    ({
      backgroundImage: `url(${
        isPregnancy ? PregnancyGuideImage : RegularGuideImage
      })`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '490px',
      // minHeight: '490px',
      width: sectionWidth,
      // minWidth: '420px',
    } as CSSProperties)

  const sectionClass = 'flex flex-col justify-center items-center'
  const h2Class = 'text-3xl font-semibold'
  const h3Class = 'text-md'
  const pClass = 'text-gray-700'
  const btnClass =
    'rounded-full bg-black text-white px-10 py-3 mt-5 mb-3 text-center'

  const reg = (
    <section
      className={`${sectionClass} rounded-3xl md:ml-4 m-2`}
      style={sectionStyle(false)}
    >
      <h2 className={h2Class}>Regular Guide</h2>
      <h3 className={h3Class}>At-home, gym or both</h3>
      <button className={btnClass} onClick={handleClick} id="regular">
        Get Started Now
      </button>
      <p className={pClass}>Starting at {regularPrice}/month</p>
    </section>
  )

  const preg = (
    <section
      className={`${sectionClass} rounded-3xl md:mr-4 md:mt-0 mt-4 ph-2 m-2`}
      style={sectionStyle(true)}
    >
      <h2 className={h2Class}>Pregnancy Guide</h2>
      <h3 className={h3Class}>At-home, gym or both</h3>
      <button className={btnClass} onClick={handleClick} id="pregnancy">
        Get Started Now
      </button>
      <p className={pClass}>Starting at {pregnancyPrice}/month</p>
    </section>
  )

  return (
    <main className="flex flex-col md:flex-row md:justify-center items-center md:items-auto mt-28">
      {hasPreviousSub ? (
        hasPregnancy ? (
          reg
        ) : (
          preg
        )
      ) : (
        <>
          {reg}
          {preg}
        </>
      )}
    </main>
  )
}
