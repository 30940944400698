import {useEffect} from 'react'
import {X} from 'react-feather'

import 'styles/modal.css'

type Props = {
  handleClose: () => void
  children: any
  title?: string
  allowEscapeClose?: boolean
  showCornerClose?: boolean
  showCardClose?: boolean
  className?: string
}

export default function Modal(props: Props) {
  const {
    handleClose,
    children,
    title = '',
    allowEscapeClose = true,
    showCornerClose = true,
    showCardClose = true,
    className = '',
  } = props

  useEffect(() => {
    allowEscapeClose && document.addEventListener('keydown', handleKeyDown)
    return (): void => {
      allowEscapeClose && document.removeEventListener('keydown', handleKeyDown)
    }
  })

  function handleKeyDown({key}: KeyboardEvent): void {
    key === 'Escape' && handleClose()
  }

  return (
    <div
      data-name="modal"
      className="fixed w-full h-full top-0 left-0 flex items-center justify-center"
    >
      <div
        data-name="modal-overlay"
        className="absolute w-full h-full bg-gray-900 opacity-50"
      />
      <div
        data-name="modal-container"
        className={`bg-white w-11/12 md:max-w-md mx-auto rounded-2xl shadow-lg z-50 overflow-y-auto`}
      >
        <div className="absolute top-0 right-0 flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
          {showCornerClose && (
            <X color="white" className="cursor-pointer" onClick={handleClose} />
          )}
          {allowEscapeClose && (
            <span className="text-sm cursor-default">(Esc)</span>
          )}
        </div>
        <div
          data-name="modal-content"
          className={`py-4 text-left px-6 ${className}`}
        >
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-bold">{title}</p>
            {showCardClose && (
              <X
                className="cursor-pointer z-50"
                color="black"
                onClick={handleClose}
              />
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
