import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios'
import {ENV} from 'constants/environment'
import LocalStorage from 'core/localStorage'

export function clientApi(): AxiosInstance {
  const instance = axios.create({
    baseURL: ENV.API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  instance.interceptors.request.use(
    async config => {
      const {headers, method} = config

      const bearerToken = LocalStorage.get('bearer_token')

      if (bearerToken) {
        headers[method!] = {
          Authorization: `Token ${bearerToken}`,
        }
      }
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    response => {
      evictIfUnAuth(response.status)
      return response
    },
    async error => {
      evictIfUnAuth(error?.response?.status)
      return Promise.reject(error)
    },
  )

  return instance
}

function evictIfUnAuth(statusCode: number) {
  if (statusCode === 401 || statusCode === 403) {
    window.location.assign('/login')
    localStorage.clear()
  }
}

export type ApiResponse<T> =
  | {
      success: true
      data: T
    }
  | {
      success: false
      data: AxiosError
    }

class Request {
  api: AxiosInstance

  constructor(apiServerName: string) {
    switch (apiServerName) {
      case 'client':
      default:
        this.api = clientApi()
        break
    }
  }

  async put<T>(path = '', params = {}): Promise<ApiResponse<T>> {
    return await this.format<T>(async () => await this.api.put<T>(path, params))
  }

  async post<T>(path = '', params = {}): Promise<ApiResponse<T>> {
    return await this.format<T>(
      async () => await this.api.post<T>(path, params),
    )
  }

  async get<T>(path = ''): Promise<ApiResponse<T>> {
    return await this.format<T>(async () => await this.api.get<T>(path))
  }

  async delete<T>(path = '', params = {}): Promise<ApiResponse<T>> {
    return await this.format<T>(
      async () => await this.api.delete<T>(path, params),
    )
  }

  async format<T>(
    request: () => Promise<AxiosResponse<T>>,
  ): Promise<ApiResponse<T>> {
    try {
      const {data} = await request()
      return {success: true, data: data as T}
    } catch (err) {
      return {success: false, data: err as AxiosError}
    }
  }
}

export const client_api = new Request('client')
