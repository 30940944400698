import {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {toast} from 'react-toastify'
import {Checkbox} from 'pretty-checkbox-react'
import {Check} from 'react-feather'

import {GuideSelections, PlanSelections} from 'types'

import {userApi, SignUpInput} from 'api/user'
import routes from 'constants/routes'
import {DATE_REGEX, PASSWORD_REGEX} from 'constants/regularExpressions'
import Modal from 'common/Modal'
import Input from 'common/inputs/Input'
import ErrorMessage from 'common/ErrorMessage'
import MainButton from 'common/buttons/MainButton'
import ProtectedInput from 'common/inputs/ProtectedInput'
import LocalStorage from 'core/localStorage'
import {truncateErrorString} from 'core/error'

type Form = SignUpInput & {has_agreed: boolean}

export default function SignUp() {
  const [showModal, setShowModal] = useState(false)
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false)
  const [hasResentEmail, setHasResentEmail] = useState(false)
  const [isResendingEmail, setIsResendingEmail] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const planSelection = params.get('plan') as PlanSelections | null
  let guideSelection = params.get('guide') as GuideSelections | null

  if (planSelection) {
    LocalStorage.set('planSelection', planSelection)
  }

  if (guideSelection) {
    LocalStorage.set('guideSelection', guideSelection)
  } else {
    guideSelection = LocalStorage.get('guideSelection')
  }

  if (!guideSelection) history.push(routes.GUIDE_SELECTION)

  const formik = useFormik<Form>({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      date_of_birth: '',
      password: '',
      confirm_password: '',
      has_agreed: false,
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: hasTriedToSubmit,
    validationSchema: yup.object().shape({
      first_name: yup.string().label('First name').required(),
      last_name: yup.string().label('Last name').required(),
      email: yup.string().email().label('Email').required(),
      date_of_birth: yup
        .string()
        .label('Date of birth')
        .required()
        .matches(DATE_REGEX, 'Must follow pattern: mm/dd/yyyy'),
      password: yup
        .string()
        .label('Password')
        .required()
        .matches(
          PASSWORD_REGEX,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
      confirm_password: yup
        .string()
        .test('passwords-match', 'Passwords must match', function (value) {
          return this.parent.password === value
        })
        .label('Password confirmation'),
      has_agreed: yup
        .bool()
        .oneOf([true], 'Must agree to terms and conditions.'),
    }),
    validate: () => setHasTriedToSubmit(true),
    onSubmit: async ({has_agreed, date_of_birth, ...values}) => {
      const [month, day, year] = date_of_birth.split('/')
      date_of_birth = `${year}-${month}-${day}`
      const response = await userApi.signUp({date_of_birth, ...values})
      if (response.success) {
        setShowModal(true)
        LocalStorage.set('bearer_token', response.data.auth_token)
        history.push(routes.ONBOARDING_COMPLETE)
      } else {
        const msg = truncateErrorString(
          response.data.response?.data ??
            'There was an error creating your account',
        )

        toast.error(msg)
      }
    },
  })

  async function resendEmail() {
    setIsResendingEmail(true)
    const response = await userApi.resendActivationEmail({
      email: formik.values.email,
    })
    if (response.success) {
      setHasResentEmail(true)
      toast.success('An email was sent')
    } else {
      const msg = truncateErrorString(
        response.data.response?.data ??
          'There was an error sending your activation email',
      )

      toast.error(msg)
    }
    setIsResendingEmail(false)
  }

  return (
    <main className="flex justify-center">
      <form
        onSubmit={formik.handleSubmit}
        className="flex justify-center flex-col md:w-5/12 sm:w-2/3 w-11/12 mt-28"
      >
        <h1 className="text-3xl mb-3">Create Your Account</h1>
        <div className="flex justify-center lg:flex-row flex-col">
          <div className="flex flex-col w-full">
            <ErrorMessage value={formik.errors.first_name} />
            <Input
              className={formik.values.first_name ? 'font-bold' : ''}
              name="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              placeholder="First Name"
            />
          </div>
          <div className="flex flex-col w-full">
            <ErrorMessage value={formik.errors.last_name} />
            <Input
              className={formik.values.last_name ? 'font-bold' : ''}
              name="last_name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              placeholder="Last Name"
            />
          </div>
        </div>
        <ErrorMessage value={formik.errors.email} />
        <Input
          className={formik.values.email ? 'font-bold' : ''}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Email"
        />
        <ErrorMessage value={formik.errors.date_of_birth} />
        <Input
          className={formik.values.date_of_birth ? 'font-bold' : ''}
          name="date_of_birth"
          value={formik.values.date_of_birth}
          onChange={formik.handleChange}
          placeholder="Date of Birth (Month/Day/Year)"
        />
        <ErrorMessage value={formik.errors.password} />
        <ProtectedInput
          className={`${formik.values.password ? 'font-bold' : ''} w-full`}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Password"
        />
        <ErrorMessage value={formik.errors.confirm_password} />
        <ProtectedInput
          className={`${
            formik.values.confirm_password ? 'font-bold' : ''
          } w-full`}
          name="confirm_password"
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          placeholder="Confirm Password"
        />
        <div>
          <div className="text-center mt-8 mb-3 flex-col justify-center items-center">
            <ErrorMessage value={formik.errors.has_agreed} />
            <div className="text-center flex justify-center items-center">
              <Checkbox
                state={formik.values.has_agreed}
                onChange={formik.handleChange}
                name="has_agreed"
                color="success"
                shape="round"
                icon={<Check className="svg" data-type="svg" />}
                animation="smooth"
                className={`md:mr-3 mr-1 ${
                  formik.errors.has_agreed ? 'outline-red-700' : ''
                }`}
              />
              <p>
                By continuing you accept our&nbsp;
                <a
                  className="underline"
                  href="https://www.wilmafit.com/privacy"
                >
                  Privacy Policy
                </a>
                &nbsp;and&nbsp;
                <a
                  className="underline"
                  href="https://www.wilmafit.com/terms-and-conditions"
                >
                  Terms of Service
                </a>
              </p>
            </div>
          </div>
          <div className="flex md:flex-row flex-col-reverse">
            <MainButton
              type="button"
              className="md:mr-3"
              onClick={() => history.push(routes.GUIDE_SELECTION)}
            >
              Go Back
            </MainButton>
            <MainButton
              type="submit"
              className="md:ml-3"
              disabled={!formik.isValid}
            >
              Submit
            </MainButton>
          </div>
        </div>
      </form>
      {showModal && (
        <Modal
          handleClose={(): void => setShowModal(false)}
          showCardClose={false}
          showCornerClose={false}
        >
          <div className="flex justify-center flex-col text-center py-8 px-10">
            {/* 
              // ! There needs to be an Image here
            */}
            <h2 className="text-4xl pb-4">Hooray!</h2>
            <p className="text-lg">
              We just sent you a confirmation link. Head over to your email to
              confirm your account.
            </p>
            <div className="flex mt-4">
              <MainButton
                className="mr-2"
                onClick={resendEmail}
                disabled={hasResentEmail}
              >
                {isResendingEmail
                  ? 'Sending...'
                  : hasResentEmail
                  ? 'Email Sent'
                  : 'Resend Email'}
              </MainButton>
              <MainButton
                className="ml-2"
                onClick={() => history.push(routes.LOGIN)}
              >
                Continue
              </MainButton>
            </div>
          </div>
        </Modal>
      )}
    </main>
  )
}
