import {FormEvent, useState} from 'react'
import dt from 'date-and-time'

import ErrorMessage from 'common/ErrorMessage'

import SubscriptionSelectionDateInput from './SubscriptionSelectionDateInput'
import MainButton from 'common/buttons/MainButton'
import LocalStorage from 'core/localStorage'
import {useUserContext} from 'context/user.context'

type ModalFormProps = {
  next: () => void
}

export type DateType = {
  m?: number
  d?: number
  y?: number
}

export default function SubscriptionSelectionModalForm({next}: ModalFormProps) {
  const [isInFirstTri, setIsInFirstTri] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>()
  const [date, setDate] = useState<DateType>({})
  const [shouldEmailForSecondTri, setShouldEmailForSecondTri] = useState(false)
  const [step, setStep] = useState<1 | 2 | 3>(1)

  const [, userDispatch] = useUserContext()

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (step === 1) finishStepOne()
    if (step === 2) finishStepTwo()
    if (step === 3) finishStepThree()
  }

  function finishStepOne() {
    if (validate()) {
      setErrorMessage(null)

      const today = new Date()
      const dueDate = new Date(date.y!, date.m! - 1, date.d!)

      const pregnantDaysLeft = dt.subtract(dueDate, today).toDays()
      const totalDaysPregnant = 273.75
      const daysPerTri = 91.25

      const isInFirstTrimester =
        pregnantDaysLeft >= totalDaysPregnant - daysPerTri
      const isInSecondOrThirdTri =
        pregnantDaysLeft < totalDaysPregnant - daysPerTri // may not need this line

      if (isInFirstTrimester) {
        setIsInFirstTri(true)
        setStep(2)
      } else if (isInSecondOrThirdTri) {
        LocalStorage.set('due_date', dueDate.toISOString())
        next()
      }
    }
  }

  function finishStepTwo() {
    if (shouldEmailForSecondTri) {
      // ! make call to backend to update user
    }

    setStep(3)
  }

  function finishStepThree() {
    LocalStorage.set('guideSelection', 'regular')
    location.reload()
  }

  function validate(): boolean {
    const {d, m, y} = date

    if (!d || !m || !y) {
      setErrorMessage('All fields must be filled')
      return false
    }

    if (d < 1 || d > 31) {
      setErrorMessage('Must be a valid day')
      return false
    }

    if (m < 1 || m > 12) {
      setErrorMessage('Must be a valid month')
      return false
    }

    if (y < 2000 || y > 2100) {
      setErrorMessage('Must be a valid year')
      return false
    }

    const today = new Date()
    const dueDate = new Date(y, m - 1, d)

    if (dueDate < today) {
      setErrorMessage('Date cannot be in the past')
      return false
    }

    const daysInNineMonths = 273.75

    if (dueDate >= dt.addDays(today, daysInNineMonths)) {
      setErrorMessage('Date is too far in the future')
      return false
    }

    return true
  }

  function handleLogout() {
    userDispatch({type: 'logout'})
  }

  return (
    <form onSubmit={handleSubmit}>
      {isInFirstTri && step !== 3 && (
        <>
          <p className="text-lg text-center">
            This guide is recommended for mamas in the second / third trimester
            of their pregnancy or looking for extra guidance through postnatal
            healing and recovery.
          </p>
          <div className="text-left flex flex-row justify-center items-center mt-8 mb-5 mx-6">
            <input
              name="has_agreed"
              checked={shouldEmailForSecondTri}
              onChange={() =>
                setShouldEmailForSecondTri(!shouldEmailForSecondTri)
              }
              type="checkbox"
              className="mr-3"
            />
            <p>
              Would you like to receive a reminder email when you reach your
              second trimester?
            </p>
          </div>
        </>
      )}
      {step === 3 && (
        <>
          <p>
            We recommend your next step be to continue with the Regular plan.
          </p>
        </>
      )}
      {!isInFirstTri && (
        <div className="flex flex-col items-center mt-10">
          <SubscriptionSelectionDateInput setDate={setDate} />
          <ErrorMessage value={errorMessage} />
        </div>
      )}
      <div className="flex justify-center">
        {step === 3 && (
          <MainButton
            onClick={handleLogout}
            className={`md:w-2/3 mr-2`}
            type="button"
          >
            Logout
          </MainButton>
        )}
        <MainButton
          className={`md:w-2/3 ${step === 3 ? 'ml-2' : ''}`}
          type="submit"
        >
          Continue
        </MainButton>
      </div>
    </form>
  )
}
