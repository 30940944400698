import {LocalStorageType as LocalStorageType} from 'types'
import {USER} from 'constants/defaults'

export default class LocalStorage {
  private static namespace = 'wilma-storage'

  /** retrieves the parsed json from localStorage of the namespace */
  public static getDefault(): LocalStorageType {
    const storageStr = localStorage.getItem(this.namespace)
    if (!storageStr) {
      this.setDefault(LOCAL_STORAGE)
      return LOCAL_STORAGE
    }
    return JSON.parse(storageStr) as LocalStorageType
  }

  /** sets the value to localStorage at the namespace */
  public static setDefault(value: LocalStorageType): void {
    localStorage.setItem(this.namespace, JSON.stringify(value))
  }

  /** retrieves one key from the parsed json */
  public static get<T extends keyof LocalStorageType>(
    key: T,
  ): LocalStorageType[T] {
    const storage = this.getDefault() ?? LOCAL_STORAGE
    return storage[key]
  }

  /** sets one key to the stored json */
  public static set<T extends keyof LocalStorageType>(
    key: T,
    value: LocalStorageType[T],
  ): void {
    const storage = this.getDefault() ?? LOCAL_STORAGE
    storage[key] = value
    this.setDefault(storage)
  }
}

export const LOCAL_STORAGE: LocalStorageType = {
  user: USER,
  guideSelection: null,
  planSelection: null,
  bearer_token: '',
  is_email_verified: false,
  due_date: null,
}
