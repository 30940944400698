import {DetailedHTMLProps, InputHTMLAttributes} from 'react'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const inputClass =
  'text-lg border-b-2 focus:border-red border-red-400 outline-none pt-3 mb-2 pb-1 w-full '

export default function Input(props: Props) {
  return <input {...props} className={`${inputClass} ${props.className}`} />
}
