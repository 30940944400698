import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'

import {internalStripeApi} from 'api/stripe'
import MainButton from 'common/buttons/MainButton'
import routes, {ONBOARDING_COMPLETE} from 'constants/routes'
import {ENV} from 'constants/environment'
import {useUserContext} from 'context/user.context'
import {truncateErrorString} from 'core/error'
import LocalStorage from 'core/localStorage'
import {USER} from 'constants/defaults'

export default function Completed() {
  const location = useLocation()
  const history = useHistory()

  let user = USER
  const [contextUser] = useUserContext()
  const localUser = LocalStorage.get('user')
  if (localUser.id !== USER.id && contextUser.id === USER.id) {
    user = localUser
  } else if (contextUser.id !== USER.id) {
    user = contextUser
  }
  const numberOfSubs = user.user_profile.subscriptions.length

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const hasJustUpdatedSubscription = !!params.get('updated')

    if (hasJustUpdatedSubscription) {
      toast.info(
        'Please allow up to 5 minutes for these changes to be reflected in the mobile app',
        {autoClose: 10000},
      )
    }
  }, [location.search])

  async function handleClick() {
    const params = new URLSearchParams()
    params.set('updated', 'true')
    const response = await internalStripeApi.createCustomerPortalSession({
      return_url: ENV.APP_URL + ONBOARDING_COMPLETE + '?' + params.toString(),
    })
    if (response.success) {
      window.location.assign(response.data.url)
    } else {
      const msg = truncateErrorString(
        response.data.response?.data ??
          'There was an error redirecting you to Customer Portal',
      )

      toast.error(msg)
    }
  }

  const isLegacy = user.user_profile.subscriptions.some(s => s.legacy)

  const textClass = 'text-2xl font-semibold'

  return (
    <main className="flex justify-center h-screen">
      <section className="flex flex-col justify-center items-center text-center mb-32 text-gray-700 mx-6 md:mx-0 w-full w-8/12 xl:w-6/12">
        {user.user_profile.has_downloaded_app ? (
          <h2 className={textClass}>Welcome Back!</h2>
        ) : isLegacy ? (
          <>
            <h2 className={textClass}>
              Welcome to the Wilma Fit App, with all the same features you loved
              about Momstrong Move, plus MORE!
            </h2>
          </>
        ) : (
          <>
            <h2 className={textClass}>Almost there!</h2>
            <h2 className={textClass}>
              Download the Wilma Fit App, login to your account,
            </h2>
            <h2 className={textClass}>and let&apos;s work out.</h2>
          </>
        )}
        <div className="flex w-full flex-col md:flex-row mt-3">
          {numberOfSubs < 2 && (
            <MainButton
              onClick={() => history.push(routes.GUIDE_SELECTION)}
              className="px-2 my-2 md:mx-2 w-1/2"
            >
              Purchase&nbsp;Another&nbsp;Subscription
            </MainButton>
          )}
          <MainButton onClick={handleClick} className="px-2 my-2 md:mx-2 w-1/2">
            View/Update&nbsp;Subscription
          </MainButton>
        </div>
        {isLegacy && (
          <div
            className="rounded-lg px-5 py-8 mt-8 shadow-md text-left"
            style={{backgroundColor: '#84DA97'}}
          >
            <p className="mb-4">
              Looks like you had a subscription with Momstrong Move. No need to
              create or pay for a new subscription here! We&apos;ve already
              transferred your current plan and everything else over for you,
              just like it was.
            </p>
            <p>
              You will now be billed for Wilma Fit from this point on instead of
              Momstrong Move. To view/update your new Wilma Fit subscription,
              click the button above.
            </p>
          </div>
        )}
      </section>
    </main>
  )
}
