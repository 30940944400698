import {useState, DetailedHTMLProps, InputHTMLAttributes} from 'react'
import {Eye, EyeOff} from 'react-feather'

import {inputClass} from './Input'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

// type onChangeEvent = MouseEvent<HTMLButtonElement, globalThis.MouseEvent>

export default function ProtectedInput(props: Props) {
  const [showProtected, setShowProtected] = useState(false)

  return (
    <div className="relative">
      <input
        {...props}
        className={`${inputClass} ${props.className}`}
        type={showProtected ? 'text' : 'password'}
      />
      <button
        type="button"
        onClick={() => setShowProtected(!showProtected)}
        className="absolute right-8 top-3 focus:outline-none"
      >
        {showProtected ? <EyeOff /> : <Eye />}
      </button>
    </div>
  )
}
