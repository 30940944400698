import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as yup from 'yup'

import routes from 'constants/routes'
import Input from 'common/inputs/Input'
import ErrorMessage from 'common/ErrorMessage'
import ProtectedInput from 'common/inputs/ProtectedInput'
import MainButton from 'common/buttons/MainButton'
import {LoginInput} from 'api/user'
import {useUserContext} from 'context/user.context'

export default function Login() {
  const [, userDispatch] = useUserContext()

  const formik = useFormik<LoginInput>({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      email: yup.string().email().label('Email').required(),
      password: yup.string().label('Password').required(),
    }),
    onSubmit: async values => {
      userDispatch({type: 'login', data: values})
    },
  })

  return (
    <main className="flex justify-center">
      <form
        onSubmit={formik.handleSubmit}
        className="flex justify-center flex-col md:w-5/12 sm:w-2/3 w-11/12 mt-28"
      >
        <h1 className="text-3xl mb-10">Welcome Back!</h1>
        <ErrorMessage value={formik.errors.email} />
        <Input
          className={formik.values.email ? 'font-bold' : ''}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Email"
        />
        <ErrorMessage value={formik.errors.password} />
        <ProtectedInput
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Password"
        />
        <div className="flex justify-end mt-5">
          <Link className="underline text-red-400" to={routes.FORGOT_PASSWORD}>
            Forgot Password?
          </Link>
        </div>
        <MainButton type="submit" className="mt-20">
          Submit
        </MainButton>
      </form>
    </main>
  )
}
