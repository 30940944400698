import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js'
import {Elements as StripeProvider} from '@stripe/react-stripe-js'
import TagManager from 'react-gtm-module'

import {UserContextProvider} from 'context/user.context'
import {ENV} from 'constants/environment'
import App from './App'

TagManager.initialize({gtmId: 'GTM-PMLGNLD'})

const stripePromise = loadStripe(ENV.REACT_APP_STRIPE_PK)

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <StripeProvider stripe={stripePromise}>
        <Router>
          <App />
        </Router>
      </StripeProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// import reportWebVitals from './reportWebVitals'
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
