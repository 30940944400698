export const PORT = process.env.PORT ?? '3113'

interface Environment {
  REACT_APP_STRIPE_PK: string
  API_URL: string
  APP_URL: string
}

const environments: Record<string, Environment> = {
  production: {
    REACT_APP_STRIPE_PK: 'pk_live_V92PpA1uKdtGEoKRLQZM9Gpa',
    API_URL: 'https://api.wilmafit.com',
    APP_URL: 'https://ap.wilmafit.com',
  },
  staging: {
    REACT_APP_STRIPE_PK: 'pk_test_tr12bq5vTsSj6cKS8fszlxdK',
    API_URL: 'https://api-staging.wilmafit.com',
    APP_URL: 'https://ap-staging.wilmafit.com',
  },
  development: {
    REACT_APP_STRIPE_PK: 'pk_test_tr12bq5vTsSj6cKS8fszlxdK',
    API_URL: 'http://0.0.0.0:8000',
    APP_URL: `http://localhost:${PORT}`,
  },
  test: {
    REACT_APP_STRIPE_PK: 'pk_test_tr12bq5vTsSj6cKS8fszlxdK',
    API_URL: 'http://0.0.0.0:8000',
    APP_URL: `http://localhost:${PORT}`,
  },
}
const envKey = process.env.REACT_APP_NODE_ENV as string
export const BLOG_URI = 'https://wilmafit.com';
export const ENV: Environment = environments[envKey]
