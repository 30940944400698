import {useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {Animated} from 'react-animated-css'
import {Menu, X} from 'react-feather'

import {ReactComponent as Logo} from 'assets/images/pink-logo.svg'

import routes from 'constants/routes'
import {useUserContext} from 'context/user.context'
import {useWindowSize} from 'hooks/useWindowSize'
import LocalStorage from 'core/localStorage'
import {USER} from 'constants/defaults'
import {BLOG_URI} from 'constants/environment'

export default function Navbar() {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [, userDispatch] = useUserContext()
  const {width} = useWindowSize()
  const history = useHistory()
  const location = useLocation()

  const awkwardWidth = 1078

  const btnClass = 'bg-red text-white rounded-lg px-8 py-2.5 focus:outline-none'

  const bearer_token = LocalStorage.get('bearer_token')
  const is_email_verified = LocalStorage.get('is_email_verified')
  const isLoggedIn = is_email_verified && !!bearer_token

  let user = USER

  const [contextUser] = useUserContext()
  const localUser = LocalStorage.get('user')
  if (localUser.id !== USER.id && contextUser.id === USER.id) {
    user = localUser
  } else if (contextUser.id !== USER.id) {
    user = contextUser
  }

  const hasSubscription = user?.user_profile?.has_stripe_account ?? false

  function handleLogout() {
    setDrawerIsOpen(false)
    userDispatch({type: 'logout'})
  }

  function handleGoToLogin() {
    setDrawerIsOpen(false)
    history.push(routes.LOGIN)
  }

  function handleGoToSignUp() {
    setDrawerIsOpen(false)
    history.push(routes.SIGN_UP)
  }

  function handleGoToAppStore() {
    window.location.href =
      'https://apps.apple.com/us/app/wilma-fit/id1514803929'
  }

  function handleGoToGooglePlayStore() {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.wilma.wilma'
  }

  return (
    <header className="flex justify-between sm:px-20 px-10 py-9 border-gray-600 border">
      <div className="flex items-center">
        <Logo
          style={{transform: 'scale(0.35)'}}
          className="cursor-pointer"
          onClick={() => (window.location.href = BLOG_URI)}
        />
      </div>
      {width < awkwardWidth ? (
        <button
          className="focus:outline-none"
          onClick={() => setDrawerIsOpen(true)}
        >
          <Menu />
        </button>
      ) : (
        <div className="flex items-center">
          <div className="px-20">
            <button
              className="pr-14 text-lg focus:outline-none"
              onClick={
                isLoggedIn
                  ? handleLogout
                  : location.pathname === routes.LOGIN
                  ? handleGoToSignUp
                  : handleGoToLogin
              }
            >
              {isLoggedIn
                ? 'Logout'
                : location.pathname === routes.LOGIN
                ? 'Sign-up'
                : 'Login'}
            </button>
            {isLoggedIn && hasSubscription && (
              <a className="pr-14 text-lg" href={routes.ONBOARDING_COMPLETE}>
                Subscriptions
              </a>
            )}
            <a className="pr-7 text-lg" href={routes.ABOUT}>
              About
            </a>
            <a className="pl-7 text-lg" href={routes.BLOG}>
              Blog
            </a>
          </div>
          <button onClick={handleGoToAppStore} className={btnClass + ' mr-2'}>
            Download for iOS
          </button>
          <button
            onClick={handleGoToGooglePlayStore}
            className={btnClass + ' ml-2'}
          >
            Download for Android
          </button>
        </div>
      )}
      <Drawer
        isOpen={drawerIsOpen}
        close={() => setDrawerIsOpen(false)}
        closeWidth={awkwardWidth}
        handleLogout={handleLogout}
        handleGoToLogin={handleGoToLogin}
        handleGoToSignUp={handleGoToSignUp}
        isLoggedIn={isLoggedIn}
        handleGoToAppStore={handleGoToAppStore}
        handleGoToGooglePlayStore={handleGoToGooglePlayStore}
        hasSubscription={hasSubscription}
      />
    </header>
  )
}

type DrawerProps = {
  isOpen: boolean
  close: () => void
  closeWidth?: number
  handleLogout: () => void
  handleGoToLogin: () => void
  handleGoToSignUp: () => void
  isLoggedIn: boolean
  handleGoToAppStore: () => void
  handleGoToGooglePlayStore: () => void
  hasSubscription: boolean
}

function Drawer({isOpen, close, ...props}: DrawerProps) {
  // prettier-ignore
  const {width, breakpoints: {md}} = useWindowSize()
  const [isHidden, setIsHidden] = useState(true)

  const closeWidth = props.closeWidth ?? md

  const duration = 800

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setIsHidden(true), duration)
    } else {
      setIsHidden(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (width > closeWidth) close()
  }, [width, closeWidth, close])

  return (
    <div
      data-name="drawer"
      className={`fixed w-full h-full top-0 left-0 flex ${
        isHidden ? 'hidden' : ''
      }`}
    >
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={isOpen}
        animationInDuration={duration}
        animationOutDuration={duration}
      >
        <div
          data-name="drawer-overlay"
          className="absolute w-full h-full bg-gray-900 opacity-50"
        />
      </Animated>
      <Animated
        data-name="drawer-container"
        animationIn="slideInRight"
        animationOut="slideOutRight"
        isVisible={isOpen}
        animationInDuration={duration}
        animationOutDuration={duration}
        className="absolute top-0 right-0 h-full z-50 bg-white sm:w-1/3 xs:w-1/2 rounded-l-xl shadow-lg pt-3"
      >
        <div className="flex justify-between text-center mb-8">
          <button className="focus:outline-none ml-3" onClick={close}>
            <X />
          </button>
          <h3 className="text-2xl mr-3">Menu</h3>
        </div>
        <div className="flex flex-col text-center">
          <button
            className="mr-4 text-xl focus:outline-none"
            onClick={
              props.isLoggedIn
                ? props.handleLogout
                : location.pathname === routes.LOGIN
                ? props.handleGoToSignUp
                : props.handleGoToLogin
            }
          >
            {props.isLoggedIn
              ? 'Logout'
              : location.pathname === routes.LOGIN
              ? 'Sign-up'
              : 'Login'}
          </button>
          {props.isLoggedIn && props.hasSubscription && (
            <Link className="text-xl mr-4 mt-6" to={routes.ONBOARDING_COMPLETE}>
              Subscriptions
            </Link>
          )}
          <Link className="text-xl mr-4 mt-6" to={routes.ABOUT}>
            About
          </Link>
          <Link className="text-xl mr-4 mt-6" to={routes.BLOG}>
            Blog
          </Link>
          <button
            onClick={props.handleGoToAppStore}
            className="bg-red text-white rounded-lg px-8 py-2.5 mx-4 mt-12"
          >
            Download for iOS
          </button>
          <button
            onClick={props.handleGoToGooglePlayStore}
            className="bg-red text-white rounded-lg px-8 py-2.5 mx-4 mt-5"
          >
            Download for Android
          </button>
        </div>
      </Animated>
    </div>
  )
}
