import {Check} from 'react-feather'

import MainButton from 'common/buttons/MainButton'
import {useHistory, useLocation} from 'react-router-dom'

// ! This page is not being utilized, for right now when the user gets the email and clicks on the link, the back-end is providing a webpage pretty much like this one. Eventually, we should use this page instead
export default function EmailVerified() {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const key = params.get('id')
  console.log(key)

  return (
    <main className="flex justify-center">
      <section className="flex justify-center text-center items-center flex-col md:w-4/12 sm:w-2/3 w-11/12 mt-28">
        <Check className="text-green-500 flex-shrink-0" size={65} />
        <h1 className="text-2xl mt-4">Your email is good to go!</h1>
        <MainButton
          className="mt-12"
          backgroundColor="red"
          type="button"
          onClick={() => history.push('/')}
        >
          Continue
        </MainButton>
        {/* Have a processing portion with a pre-loader, then display is user has
        been successfully authenticated. move to authenticated portion */}
      </section>
    </main>
  )
}
