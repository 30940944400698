import {DetailedHTMLProps, ButtonHTMLAttributes} from 'react'

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  /** default: true */
  isActive?: boolean
  activeClass?: string
}

export default function OutlineButton(props: ButtonProps) {
  const {
    isActive = true,
    activeClass = '',
    className,
    children,
    ...rest
  } = props
  return (
    <button
      {...rest}
      className={`focus:outline-none border-2 rounded-lg w-full py-4 ${
        isActive ? activeClass : 'border-red-300'
      } ${className ?? ''}`}
    >
      {children}
    </button>
  )
}
