import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import LocalStorage from 'core/localStorage'
import {useUserContext} from 'context/user.context'
import {USER} from 'constants/defaults'
import routes from 'constants/routes'

type Props = {
  children: React.ReactNode
  requiresAuth?: true
  requiresSubscription?: true
  noAuth?: true
}

export function RouteAuthorization(props: Props) {
  const {requiresSubscription, noAuth} = props

  let user = USER

  const [contextUser] = useUserContext()
  const localUser = LocalStorage.get('user')
  const is_email_verified = LocalStorage.get('is_email_verified')
  const bearer_token = LocalStorage.get('bearer_token')

  if (localUser.id !== USER.id && contextUser.id === USER.id) {
    user = localUser
  } else if (contextUser.id !== USER.id) {
    user = contextUser
  }

  const hasSubscription = user?.user_profile?.has_stripe_account ?? false
  const isLoggedIn = is_email_verified && !!bearer_token
  const location = useLocation();
  const currentParams = location.search;

  if (noAuth) {
    if (isLoggedIn) {
      return <Redirect to={`${routes.ONBOARDING_COMPLETE}${currentParams}`} />
    } else {
      return <>{props.children}</>
    }
  }

  if (!isLoggedIn) {
    return <Redirect to={`/${currentParams}`} />
  }

  // if (requiresAuth && hasSubscription) {
  //   return <Redirect to="/" />
  // }

  if (requiresSubscription && !hasSubscription) {
    return <Redirect to={`/${currentParams}`} />
  }

  return <>{props.children}</>
}
