import {User} from 'types'

export const USER: User = {
  id: 0,
  email: '',
  user_profile: {
    id: 0,
    subscriptions: [],
    date_of_birth: null,
    state: null,
    moonclerk_id: null,
    device_token: null,
    message_note: null,
    recent_purchase: null,
    is_email_verified: false,
    avatar: null,
    email_at_13_weeks: null,
    has_stripe_account: false,
    has_downloaded_app: false,
    user: 0,
  },
  last_login: '',
  is_superuser: false,
  username: '',
  first_name: '',
  last_name: '',
  is_staff: false,
  is_active: false,
  date_joined: '',
  groups: [],
  user_permissions: [],
}
