import {useState, MouseEvent} from 'react'
import {Redirect, useHistory} from 'react-router-dom'

import {ValidPlanSelections} from 'types'
import {ReactComponent as PregnancyIcon} from 'assets/icons/pregnancy-icon.svg'
import {ReactComponent as AtHomeIcon} from 'assets/icons/at-home-icon.svg'
import {ReactComponent as GymIcon} from 'assets/icons/gym-icon.svg'
import routes from 'constants/routes'
import MainButton from 'common/buttons/MainButton'
import LocalStorage from 'core/localStorage'

export default function PlanSelection() {
  const history = useHistory()
  const [planSelection, setPlanSelection] = useState<ValidPlanSelections>(
    'gym-and-home',
  )

  const selection = LocalStorage.get('guideSelection')
  if (!selection) return <Redirect to={routes.GUIDE_SELECTION} />
  const isPregnancy = selection === 'pregnancy'

  const planSelectionLS = LocalStorage.get('planSelection')
  if (planSelectionLS !== planSelection && planSelectionLS) {
    setPlanSelection(planSelectionLS)
  } else if (!planSelectionLS) {
    LocalStorage.set('planSelection', planSelection)
  }

  // prettier-ignore
  function handleClick(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    const newType = (e.target as HTMLButtonElement).name as ValidPlanSelections
    LocalStorage.set('planSelection', newType)
    setPlanSelection(newType)
  }

  function handleNext() {
    history.push(routes.SUBSCRIPTION_SELECTION)
  }

  function buttonStyles(type: ValidPlanSelections) {
    return `w-full focus:outline-none py-4 rounded-lg text-lg font-semibold disabled:opacity-50 disabled:cursor-not-allowed ${
      type !== 'home' ? 'mb-5' : ''
    } ${planSelection === type ? 'bg-red text-white' : 'bg-white'}`
  }

  return (
    <main className="flex justify-center">
      <section
        className={`flex items-center flex-col xl:w-3/12 lg:w-5/12 md:w-6/12 sm:w-2/3 w-11/12 mt-10 text-center py-4 px-7 rounded-3xl text-gray-700 ${
          isPregnancy ? 'bg-light-blue' : 'bg-peach'
        }`}
      >
        <div
          style={{maxWidth: '280px'}}
          className="flex justify-center px-4 flex-col"
        >
          <div className="flex justify-center">
            {isPregnancy ? (
              <PregnancyIcon />
            ) : (
              <>
                <AtHomeIcon />
                <GymIcon width={64} height={64} />
              </>
            )}
          </div>
          <h1 className="text-3xl font-semibold mt-5 mb-10 text-lg">
            {isPregnancy ? 'Pregnancy' : 'Regular'} Guide
          </h1>
          <h2 className="font-semibold mb-3">Select Your Program</h2>
          <p className="text-center mb-4">
            No matter your goals, we have a program for you!
          </p>
          <button
            name="gym-and-home"
            onClick={handleClick}
            className={buttonStyles('gym-and-home')}
          >
            At-Home + Gym Guide
          </button>
          <button
            name="gym"
            onClick={handleClick}
            className={buttonStyles('gym')}
          >
            Gym Guide
          </button>
          <button
            name="home"
            onClick={handleClick}
            className={buttonStyles('home')}
          >
            At-Home Guide
          </button>
        </div>
        {/* <small className="mt-8 mb-1">Or Try a Free 7-Day Trial</small> */}
        <div className="flex md:flex-row flex-col-reverse w-full mt-4">
          <MainButton
            type="button"
            className="md:mr-3"
            onClick={() => history.push(routes.GUIDE_SELECTION)}
          >
            Go Back
          </MainButton>
          <MainButton onClick={handleNext} type="button" className="md:ml-3">
            Next
          </MainButton>
        </div>
      </section>
    </main>
  )
}
