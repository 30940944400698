import {client_api} from './index'
import {User} from 'types'

// prettier-ignore
export const userApi = {
  signUp: async (body: SignUpInput) => client_api.post<SignUpResponse>('/users/signup/', body),
  login: async (body: LoginInput) => client_api.post<loginResponse>('/rest-auth/login/', body),
  logout: async () => client_api.post<loginResponse>('/rest-auth/logout/'),
  forgotPassword: async (body: ForgotPasswordInput) => client_api.post('/users/forgot_password/', body),
  resetPassword: async (body: ResetPasswordBody) => client_api.post('/users/reset_password/', body) ,
  getUser: async () => client_api.get<User>('/users/get_user/'),
  resendActivationEmail: async (body: ResendVerificationEmail) => client_api.get(`/users/resend_activation_verification/?email=${body.email}`)
}

export type SignUpInput = {
  first_name: string
  last_name: string
  email: string
  date_of_birth: string
  password: string
  confirm_password: string
}

export type SignUpResponse = {
  auth_token: string
}

export type LoginInput = {
  email: string
  password: string
}

export type ForgotPasswordInput = {
  email: string
}

export type ResendVerificationEmail = {
  email: string
}

export type loginResponse = {
  key: string
}

export type ResetPasswordBody = {
  new_password: string
  confirm_password: string
  confirmation_code: string
}
