import {BLOG_URI} from 'constants/environment'

export const ONBOARDING = '/onboarding'
export const GUIDE_SELECTION = '/onboarding/guide'
export const SIGN_UP = '/onboarding/sign-up'
export const EMAIL_VERIFIED = '/onboarding/email-verified/:id'
export const PLAN_SELECTION = '/onboarding/plan'
export const SUBSCRIPTION_SELECTION = '/onboarding/subscription'
export const SUBSCRIPTION_FINALIZATION = '/onboarding/finalization'
export const ONBOARDING_COMPLETE = '/onboarding/complete'
export const ONBOARDING_ROUTES = [
  ONBOARDING,
  GUIDE_SELECTION,
  SIGN_UP,
  EMAIL_VERIFIED,
  PLAN_SELECTION,
  SUBSCRIPTION_SELECTION,
  ONBOARDING_COMPLETE,
  SUBSCRIPTION_FINALIZATION,
]
export const LOGIN = '/login'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESEND_VERIFICATION = '/resend-verification-email'
export const RESET_PASSWORD = '/reset-password/:token'
export const ABOUT = `${BLOG_URI}/about`
export const BLOG = `${BLOG_URI}/blog`
export const PUBLIC_ROUTES = [
  LOGIN,
  FORGOT_PASSWORD,
  RESEND_VERIFICATION,
  RESET_PASSWORD,
  ABOUT,
  BLOG,
]

export default {
  ONBOARDING,
  GUIDE_SELECTION,
  SIGN_UP,
  EMAIL_VERIFIED,
  PLAN_SELECTION,
  ONBOARDING_ROUTES,
  ABOUT,
  BLOG,
  PUBLIC_ROUTES,
  LOGIN,
  FORGOT_PASSWORD,
  SUBSCRIPTION_SELECTION,
  ONBOARDING_COMPLETE,
  SUBSCRIPTION_FINALIZATION,
  RESEND_VERIFICATION,
  RESET_PASSWORD,
}
