import {useEffect, useState} from 'react'
import {ToastContainer} from 'react-toastify'

import 'pretty-checkbox'
import 'react-toastify/dist/ReactToastify.css'

import 'styles/App.css'
import 'styles/Loaders.css'
import 'styles/tailwind.css'

import {userApi} from 'api/user'
import {USER} from 'constants/defaults'
import {useUserContext} from 'context/user.context'
import Navbar from 'common/Navbar'
import MainRouter from 'components/router/MainRouter'
import LocalStorage from 'core/localStorage'

export default function App() {
  const [isLoading, setIsLoading] = useState(false)
  const [almostDoneLoading, setAlmostDoneLoading] = useState(false)
  const [user, userDispatch] = useUserContext()

  const bearer_token = LocalStorage.get('bearer_token')

  const contextClass = {
    success: 'bg-green-600',
    error: 'bg-red-600',
    info: 'bg-gray-600',
    default: 'bg-orange',
    warning: 'bg-indigo-600',
    dark: 'bg-white-600 font-gray-300',
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)

      const response = await userApi.getUser()

      if (response.success) {
        userDispatch({type: 'set', data: response.data})
      }

      setTimeout(() => {
        setIsLoading(false)
        setAlmostDoneLoading(false)
      }, 1000)

      setAlmostDoneLoading(true)
    }

    if (user.id === USER.id && bearer_token) {
      setIsLoading(true)
      fetchData()
    }
  }, [user.id, userDispatch, bearer_token])

  return (
    <>
      <Navbar />
      <MainRouter isLoading={isLoading} almostDoneLoading={almostDoneLoading} />
      <ToastContainer
        toastClassName={props =>
          contextClass[props?.type ?? 'default'] +
          ' flex px-3 py-2 min-h-10 text-lg rounded-lg justify-between overflow-hidden cursor-pointer'
        }
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
