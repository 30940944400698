import {Price} from 'api/stripe'
import MainButton from 'common/buttons/MainButton'
import OutlineButton from 'common/buttons/OutlineButton'

type FormProps = {
  onSubmit: () => void
  isPregnancy: boolean
  onGoBack: () => void
  setSubscriptionChoice: (subscriptionChoice: SubscriptionChoices) => void
  subscriptionChoice: SubscriptionChoices
  prices: Price[]
}

export type SubscriptionChoices = 'year' | 'month' | 'FreeTrial' | null

export default function SubscriptionSelectionForm(props: FormProps) {
  function handleChoice(plan: SubscriptionChoices) {
    return () => props.setSubscriptionChoice(plan)
  }

  function handleNext() {
    props.onSubmit()
  }

  const pricesArr = props.prices.map(price => ({
    amount: price.unit_amount / 100,
    interval: price.recurring.interval,
  }))

  const yearPrice = pricesArr.find(p => p.interval === 'year')?.amount
  const monthPrice = pricesArr.find(p => p.interval === 'month')?.amount

  const outlineClass = props.isPregnancy ? 'border-red' : 'border-black'

  return (
    <div className="flex flex-col items-center text-center px-10 bg-white rounded-3xl mx-5 mb-5">
      <h2 className="mb-2 mt-6 text-xl">Select Your Subscription</h2>
      {yearPrice && (
        <div className="relative w-full">
          <div className="absolute right-6 -top-6 bg-red text-white rounded-full leading-none p-2 text-sm">
            <p className="text-white font-bold pb-1">BEST</p>
            <p className="text-white font-bold">VALUE</p>
          </div>
          <OutlineButton
            isActive={props.subscriptionChoice === 'year'}
            activeClass={outlineClass}
            className={`mb-6 w-full`}
            onClick={handleChoice('year')}
          >
            <p>
              <span className="font-semibold">${yearPrice}</span> / Year
            </p>
          </OutlineButton>
        </div>
      )}
      {monthPrice && (
        <OutlineButton
          isActive={props.subscriptionChoice === 'month'}
          activeClass={outlineClass}
          className={`mb-6`}
          onClick={handleChoice('month')}
        >
          <p>
            <span className="font-semibold">${monthPrice}</span> / Month
          </p>
        </OutlineButton>
      )}
      {/* <OutlineButton
        isActive={subscriptionChoice === 'FreeTrial'}
        activeClass={outlineClass}
        className={`mb-6`}
        onClick={handleChoice('FreeTrial')}
      >
        <p>
          <span className="font-semibold">$0.00</span> / FreeTrial
        </p>
      </OutlineButton> */}
      <div className="flex md:flex-row flex-col-reverse w-full mb-10">
        <MainButton type="button" className="md:mr-3" onClick={props.onGoBack}>
          Go Back
        </MainButton>
        <MainButton
          disabled={!props.subscriptionChoice}
          onClick={handleNext}
          type="button"
          className="md:ml-3"
        >
          Next
        </MainButton>
      </div>
    </div>
  )
}
