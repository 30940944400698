import {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {toast} from 'react-toastify'

import {userApi, ForgotPasswordInput} from 'api/user'
import routes from 'constants/routes'
import {truncateErrorString} from 'core/error'
import Modal from 'common/Modal'
import Input from 'common/inputs/Input'
import ErrorMessage from 'common/ErrorMessage'
import MainButton from 'common/buttons/MainButton'

export default function ResendVerificationEmail() {
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()

  const formik = useFormik<ForgotPasswordInput>({
    initialValues: {
      email: '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      email: yup.string().email().label('Email').required(),
    }),
    onSubmit: async values => {
      const response = await userApi.resendActivationEmail(values)
      if (response.success) {
        setShowModal(true)
        setTimeout(() => history.push(routes.LOGIN), 7000)
      } else {
        const msg = truncateErrorString(
          response.data.response?.data ?? 'Something went wrong...',
        )
        toast.error(msg)
      }
    },
  })

  return (
    <main className="flex justify-center">
      <form
        onSubmit={formik.handleSubmit}
        className="flex justify-center flex-col md:w-5/12 sm:w-2/3 w-11/12 mt-28"
      >
        <h1 className="text-3xl mb-10">Resend Account Activation Email</h1>
        <ErrorMessage value={formik.errors.email} />
        <Input
          className={formik.values.email ? 'font-bold' : ''}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Email"
        />
        <div className="flex justify-end mt-5">
          <Link className="underline" to={routes.LOGIN}>
            Back to Login
          </Link>
        </div>
        <MainButton type="submit" className="mt-20">
          Send Reset Link
        </MainButton>
      </form>
      {showModal && (
        <Modal handleClose={() => setShowModal(false)} title="Success!">
          Another email has been sent to that email address. Please hurry to
          verify your email. You are being redirected back to the login page.
        </Modal>
      )}
    </main>
  )
}
