import {client_api} from './index'

// prettier-ignore
export const internalStripeApi = {
  createCheckoutSession: async (body: CheckoutSessionInput) => client_api.post<CheckoutSessionResponse>('/stripe/create_checkout_session/', body),
  createCustomerPortalSession: async (body: CustomerPortalSessionInput) => client_api.post<CustomerSessionResponse>('/stripe/create_customer_portal_session/', body),
  getProducts: async () => client_api.get<MergedProductAndPrice[]>('/stripe/products/'),
  saveUserCustomerId: async (body: SaveUserCustomerIdInput) => client_api.post('/stripe/save_user_customer_id/', {
    session_id: body.session_id,
    due_date: body.due_date?.split('T')?.shift(),
  }),
}

export type CheckoutSessionInput = {
  price_id: string
  success_url: string
  cancel_url: string
  email: string
  coupon_code?: string
  client_reference_id: any
}

export type CustomerPortalSessionInput = {
  return_url: string
}

export type CheckoutSessionResponse = {
  session_id: string
}

export type CustomerSessionResponse = {
  id: string
  object: 'billing_portal.session'
  created: number
  customer: string
  livemode: boolean
  return_url: string
  url: string
}

export type SaveUserCustomerIdInput = {
  session_id: string
  due_date: string | null
}

export type MergedProductAndPrice = Product & {prices: Price[]}

export type Product = {
  /** Whether the product is currently available for purchase. */
  active?: boolean | null
  /** A list of up to 5 attributes that each SKU can provide values for (e.g., `["color", "size"]`). Only applicable to products of `type=good`. */
  attributes?: string[] | null
  /** A short one-line description of the product, meant to be displayable to the customer. Only applicable to products of `type=good`. */
  caption?: string | null
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: number
  /** An array of connect application identifiers that cannot purchase this product. Only applicable to products of `type=good`. */
  deactivate_on?: any[]
  /** The product's description, meant to be displayable to the customer. Only applicable to products of `type=good`. */
  description?: string | null
  /** Unique identifier for the object. */
  id: string
  /** A list of up to 8 URLs of images for this product, meant to be displayable to the customer. Only applicable to products of `type=good`. */
  images: string[]
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: boolean
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata: {[key: string]: string}
  /** The product's name, meant to be displayable to the customer. Applicable to both `service` and `good` types. */
  name: string
  /** String representing the object's type. Objects of the same type share the same value. */
  object: 'product'
  /** The dimensions of this product for shipping purposes. A SKU associated with this product can override this value by having its own `package_dimensions`. Only applicable to products of `type=good`. */
  package_dimensions?: null
  /** Whether this product is a shipped good. Only applicable to products of `type=good`. */
  shippable?: boolean | null
  /** Extra information about a product which will appear on your customer's credit card statement. In the case that multiple products are billed at once, the first statement descriptor will be used. Only available on products of type=`service`. */
  statement_descriptor?: string | null
  /** A label that represents units of this product, such as seat(s), in Stripe and on customers’ receipts and invoices. Only available on products of type=`service`. */
  unit_label?: string | null
  updated: number
  /** A URL of a publicly-accessible webpage for this product. Only applicable to products of `type=good`. */
  url?: string | null
}

export type Price = {
  /** Unique identifier for the object. */
  id: string
  /** String representing the object’s type. Objects of the same type share the same value. */
  object: string
  /** Whether the price can be used for new purchases. */
  active: true
  /** Describes how to compute the price per period. Either per_unit or tiered. per_unit indicates that the fixed amount (specified in unit_amount or unit_amount_decimal) will be charged per unit in quantity (for prices with usage_type=licensed), or per unit of total usage (for prices with usage_type=metered). tiered indicates that the unit pricing will be computed using a tiering strategy as defined using the tiers and tiers_mode attributes. */
  billing_scheme: string
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: number
  /** Three-letter ISO currency code, in lowercase. Must be a supported currency. */
  currency: string
  /** Has the value true if the object exists in live mode or the value false if the object exists in test mode. */
  livemode: boolean
  /** A lookup key used to retrieve prices dynamically from a static string. */
  lookup_key?: string | null
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata: {[key: string]: string}
  /** A brief description of the plan, hidden from customers. */
  nickname?: string | null
  /** The ID of the product this price is associated with. */
  product: string
  /** The recurring components of a price such as interval and usage_type. */
  recurring: {
    /** Specifies a usage aggregation strategy for prices of usage_type=metered. Allowed values are sum for summing up all usage during a period, last_during_period for using the last usage record reported within a period, last_ever for using the last usage record ever (across period bounds) or max which uses the usage record with the maximum reported usage during a period. Defaults to sum. */
    aggregate_usage: null
    /** The frequency at which a subscription is billed. */
    interval: 'day' | 'week' | 'month' | 'year'
    /** The number of intervals (specified in the interval attribute) between subscription billings. For example, interval=month and interval_count=3 bills every 3 months. */
    interval_count: 1
    /** Configures how the quantity per period should be determined. Can be either metered or licensed. licensed automatically bills the quantity set when adding it to a subscription. metered aggregates the total usage based on usage records. Defaults to licensed. */
    usage_type: string
  }
  /** Defines if the tiering price should be graduated or volume based. In volume-based tiering, the maximum quantity within a period determines the per unit price. In graduated tiering, pricing can change as the quantity grows. */
  tiers_mode?: string | null
  /** Apply a transformation to the reported usage or set quantity before computing the amount billed. Cannot be combined with tiers. */
  transform_quantity?: {
    /** Divide usage by this number. */
    divide_by: number
    /** After division, either round the result up or down. */
    round: 'up' | 'down'
  } | null
  /** One of one_time or recurring depending on whether the price is for a one-time purchase or a recurring (subscription) purchase. */
  type: 'one_time' | 'recurring'
  /** The unit amount in cents to be charged, represented as a whole integer if possible. */
  unit_amount: number
  /** The unit amount in cents to be charged, represented as a decimal string with at most 12 decimal places. */
  unit_amount_decimal: string
}
