import {Animated} from 'react-animated-css'

type Props = {
  almostDoneLoading?: boolean
}

export default function LoadingPage({almostDoneLoading = false}: Props) {
  const duration = 800

  return (
    <main className="flex justify-center items-center h-screen">
      <section className="mb-16">
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          isVisible={!almostDoneLoading}
          animationInDuration={duration}
          animationOutDuration={duration}
        >
          <div className="flex justify-center">
            <div className="spinner-secondary-xl mr-4 mb-5" />
          </div>
          <h1 className="text-2xl text-center">Loading...</h1>
        </Animated>
      </section>
    </main>
  )
}
