import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import {
  LOGIN,
  FORGOT_PASSWORD,
  RESEND_VERIFICATION,
  RESET_PASSWORD,
  GUIDE_SELECTION,
  ONBOARDING_COMPLETE,
  PLAN_SELECTION,
  SUBSCRIPTION_SELECTION,
  SUBSCRIPTION_FINALIZATION,
  SIGN_UP,
  EMAIL_VERIFIED,
} from 'constants/routes'

import ForgotPassword from 'components/authentication/ForgotPassword'
import Login from 'components/authentication/Login'
import ResendVerificationEmail from 'components/authentication/ResendVerificationEmail'
import ResetPassword from 'components/authentication/ResetPassword'
import Completed from 'components/onboarding/Completed'
import EmailVerified from 'components/onboarding/EmailVerified'
import GuideSelection from 'components/onboarding/GuideSelection'
import PlanSelection from 'components/onboarding/PlanSelection'
import SignUp from 'components/onboarding/SignUp'
import SubscriptionSelection from 'components/onboarding/subscription-selection/SubscriptionSelection'
import SubscriptionFinalization from 'components/onboarding/subscription-selection/SubscriptionFinalization'

import LocalStorage from 'core/localStorage'

import {RouteAuthorization} from './RouteAuthorization'
import LoadingPage from 'components/authentication/LoadingPage'

type Props = {
  isLoading: boolean
  almostDoneLoading: boolean
}

export default function MainRouter({isLoading, almostDoneLoading}: Props) {
  const bearer_token = LocalStorage.get('bearer_token')
  const is_email_verified = LocalStorage.get('is_email_verified')
  const isAuth = is_email_verified && !!bearer_token

  if (isLoading) {
    return <LoadingPage almostDoneLoading={almostDoneLoading} />
  }

  return (
    <Switch>
      <Route exact path={LOGIN}>
        <RouteAuthorization noAuth>
          <Login />
        </RouteAuthorization>
      </Route>
      <Route exact path={FORGOT_PASSWORD}>
        <RouteAuthorization noAuth>
          <ForgotPassword />
        </RouteAuthorization>
      </Route>
      <Route exact path={RESEND_VERIFICATION}>
        <ResendVerificationEmail />
      </Route>
      <Route exact path={RESET_PASSWORD}>
        <ResetPassword />
      </Route>
      <Route exact path={SIGN_UP}>
        <RouteAuthorization noAuth>
          <SignUp />
        </RouteAuthorization>
      </Route>
      <Route exact path={EMAIL_VERIFIED}>
        <EmailVerified />
      </Route>

      <Route exact path={GUIDE_SELECTION}>
        <GuideSelection isAuth={isAuth} />
      </Route>

      <Route exact path={ONBOARDING_COMPLETE}>
        <RouteAuthorization requiresSubscription>
          <Completed />
        </RouteAuthorization>
      </Route>

      <Route exact path={PLAN_SELECTION}>
        <RouteAuthorization requiresAuth>
          <PlanSelection />
        </RouteAuthorization>
      </Route>
      <Route exact path={SUBSCRIPTION_SELECTION}>
        <RouteAuthorization requiresAuth>
          <SubscriptionSelection />
        </RouteAuthorization>
      </Route>
      <Route exact path={SUBSCRIPTION_FINALIZATION}>
        <RouteAuthorization requiresAuth>
          <SubscriptionFinalization />
        </RouteAuthorization>
      </Route>
      <Redirect to={LOGIN} />
    </Switch>
  )
}
