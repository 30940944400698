import {DetailedHTMLProps, ButtonHTMLAttributes} from 'react'

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  /** any tailwind color (prefixes are already added) */
  backgroundColor?: string
  /** any tailwind color (prefixes are already added) */
  color?: string
  /** any tailwind color (prefixes are already added) */
  disabledColor?: string
}

export default function MainButton(props: Props) {
  const {
    backgroundColor = 'red',
    color = 'white',
    disabledColor = 'gray-400',
    ...rest
  } = props

  return (
    <button
      {...rest}
      className={`focus:outline-none rounded-full bg-${
        props.disabled ? disabledColor : backgroundColor
      } text-${color} text-white w-full py-3 mt-5 text-center ${
        props.className
      }`}
    >
      {props.children}
    </button>
  )
}
